import { formatForAxis } from "./StringFormatHelpers";

export function refId2Date(ref: string): Date {
    return new Date(ref.replace("- Interval ", ''));
}

export const X_TICK_INTERVAL: number = 1000 * 60 * 60 * 2;
export const FIFTEEN_MIN_MS = 1000 * 60 * 15;

export function get4Dates(instant: Date | string): [Date, string, Date, string]{
    const mktDay = new Date(instant);
    const mktDayFriendly = formatForAxis(mktDay);
    const nxtDayDt = new Date(mktDay.getTime() + 1000 * 60 * 60 * 24);
    const nxtDayFriendly = formatForAxis(nxtDayDt);
    return [mktDay, mktDayFriendly, nxtDayDt, nxtDayFriendly];
}

export function convertDateTimeToISO(dateString: string): Date {
    // Example format: "2024-09-11 2:30:00 PM" -> 2024-09-11T14:30:00 (Date)
    let dateTimePieces = dateString.split(" ");
    let datePieces = dateTimePieces[0].split("-");
    let timePieces = dateTimePieces[1].split(":");

    let hour = Number(timePieces[0]);
    let period = dateTimePieces[2]; // "AM" or "PM"

    // Correct AM/PM hour adjustment
    if (period === "AM") {
        if (hour === 12) {
            hour = 0; // Midnight (12 AM)
        }
    } else if (period === "PM") {
        if (hour !== 12) {
            hour += 12; // Convert PM hours to 24-hour format
        }
    }

    // Pad hour to always be two digits
    let hourString = hour < 10 ? "0" + hour : String(hour);

    // Construct the ISO string
    let newlyConstructedDateTimeString = `${datePieces[0]}-${datePieces[1]}-${datePieces[2]}T${hourString}:${timePieces[1]}:${timePieces[2]}`;

    return new Date(newlyConstructedDateTimeString);
}

export function convertSimpleDateToISOFormat(dateString: string): Date {
    let datePieces = dateString.split("-");
    return new Date(`${datePieces[2]}-${datePieces[0]}-${datePieces[1]}T00:00:00`);
}