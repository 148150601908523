export const MisoColors = {
    "misoBlue": "#0082CA",
    "lightBlue": "#3DB5E6",
    "darkBlue": "#005880",
    "borderBlue": "#5AC0EA",
    "misoGreen": "#80BC00",
    "veryLightGreen": "#e5f2d9",
    "lightGreen": "#C3E86C",
    "mediumGreen": "#7fbc42",
    "darkGreen": "#5A8300",
    "lightGray": "#9CAFAC",
    "gray": "#7F8F90",
    "borderGray": "#CDD7D8",
    "red": "#B32820",
    "nearWhite": "#E5E5E5",
    "orange": "#F79646",
    "purple": "#B1A0C7",
    "burntOrange": "#C14E00",
    "brickRed": "#802629",
    "yellow": "#F4C400",
    "peaGreen": "#8A7B29",
    "royalBlue": "#000B8C",
    "teal": "#00567D",
    "merlot": "#762157",
    "ltMerlot": "#C129B9",
    "grape": "#2F1A45",
    "emerald": "#4A7628",
    "darkGray": "#5C6670",
    "warmGray": "#706259",
    "misoDrkGry": "#636569",
    "misoOrange": "#ee7623",
    "misoLgtGry": "#a7a8aa",
    "misoLgtOrng": "#ffc844",
    "misoRdtLmt": "#B40606",
    "misoGenOtg1": "#004d71",
    "misoGenOtg2": "#36413E",
    "misoGenOtg3": "#D4DCFF",
    "misoGenOtg4": "#E0A458",
    "misoSolar1": "#E0A458",
    "misoSolar2": "#904E55",
    "black": "#000000",
    "darkTeal": "#4b858e"
}