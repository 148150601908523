import { useEffect, useState, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'

import { MisoColors } from '../colors';
import { ACE_SUFFIX, DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, POPOUT_MESSAGE } from '../Constants';
import { ChartProps, XYPoint } from '../Interfaces/Charts';
import VCenteredModal from '../Components/VCenteredModal';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Button } from 'react-bootstrap';
import { Download } from '../Utils/Downloader';
import { formatForAxis, formatNumber } from '../Utils/StringFormatHelpers';
import { formatTooltipDateWithMinutes } from '../Utils/ToolTipHelpers';
import { ACEDataInstance, ACEData } from '../Interfaces/DataBroker';

let acePlotPoints: XYPoint[] = [];

let mktDay: Date;
let nxtDayDt: Date;
let nxtDayFriendly: string;
let mktDayFriendly: string;

let intervalString: string;

function resetSeries() {
    acePlotPoints = [];
}

function parseData(data: ACEData): void {
    mktDay = new Date(data.MktDay);
    mktDay.setHours(0);
    nxtDayDt = new Date(mktDay);
    nxtDayDt.setDate(mktDay.getDate() + 1);

    intervalString = data.RefId;

    // friend-ify dates
    mktDayFriendly = formatForAxis(mktDay);
    nxtDayFriendly = formatForAxis(nxtDayDt);

    resetSeries();

    data.ACE.forEach((aceInstance: ACEDataInstance) => {
        const x = new Date(aceInstance.instantEST).getTime();
        acePlotPoints.push({
            x,
            y: parseFloat(aceInstance.value)
        });
    });
}

let initialOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    title:{
        text: ''
    },
    lang: {
        noData: "No data was received"
    },
    plotOptions: {
        line: {
            marker: {
                enabled: false,
            },
        },
    },
    xAxis: [
        // Main axis
        {
            title: {
                text: `Time EST`,
                style: {
                    fontWeight: 'bold',
                    color: MisoColors.black,
                },
            },
        },
    ],
    yAxis: {
        title:{
            text: null
        },
    },
    tooltip: {
        shared: true,
        formatter: function() {
            if(this.points == null) return false;
            const exactMomentInDay = new Date(this.x as string);
            return this.points.reduce(formatTooltip, '<b>' + formatTooltipDateWithMinutes(exactMomentInDay) + '</b>');
        }
    },
    series: []
};

function formatTooltip(s: string, point: Highcharts.TooltipFormatterContextObject): string{    
    const y = point.y as number;

    // keep the existing tooltip, add break line
    var tooltip = s + '<br />';

    tooltip += '<span style="color:'+ point.color +'">\u25cf</span> '+ point.series.name +': <b>' + formatNumber(y) +'</b>';
    
    return tooltip;
}

export default function AreaControlError(props: ChartProps) {
    
    const [options, setOptions] = useState(initialOptions);
    const chartRef = useRef<HighchartsReactRefObject>(null);
    const [buttonClicked] = [props.buttonClicked];
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState<React.ReactNode>(<></>);
    const modalHeader: React.ReactNode = <span>ACE Chart</span>;
    
    
    NoDataToDisplay(Highcharts);
    
    useEffect(() => {
        const descriptionBody: React.ReactNode = <><span>The Area Control Error (ACE) chart provides a close to real-time display of the current ACE value for the MISO footprint. The chart is updated every 30 seconds.</span></>;
        let downloadBody: React.ReactNode = <div className="download-modal">
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${ACE_SUFFIX}`, "AreaControlError", "json")}>Download JSON</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${ACE_SUFFIX}`.replace("json", "csv"), "AreaControlError", "csv")}>Download CSV</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${ACE_SUFFIX}`.replace("json", "xml"), "AreaControlError", "xml")}>Download XML</Button>
        </div>;

        switch (buttonClicked) {
            case DESCRIPTION_MESSAGE:
                setModalBody(descriptionBody);
                setShowModal(true);
                break;
            case DOWNLOAD_MESSAGE:
                setModalBody(downloadBody);
                setShowModal(true);
                break;
            case POPOUT_MESSAGE:
                const newWinddow = window.open('/charts/ace', '_blank', 'width=800,height=600,noopener,noreferrer');
                if (newWinddow) newWinddow.opener = null;
                break;
            default:
                break;
        }
    }, [buttonClicked]);
    useEffect(() => {
        function fetchData(): void {
            if(chartRef.current === null){
                return;
            }
            const chart = chartRef.current.chart;
            chart.showLoading();
            fetch (process.env.REACT_APP_DATABROKER_URL + '' + ACE_SUFFIX)
            .then(response => { 
                return response.json();
            })
            .then(data => {
                parseData(data);
                setOptions({
                    xAxis: [
                        // Main axis
                        {
                            title: {
                                text: `Time EST`,
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                            },
                            labels: {
                                formatter: function(this: Highcharts.AxisLabelsFormatterContextObject) {
                                    var dt = new Date(this.value);
                                    return `${dt.getHours()}:${String(dt.getMinutes()).padStart(2, '0')}`;
                                }
                            },
                            tickPositioner: () => { // Position ticks on every 15 minute interval of each hour.
                                const positions = [];
                                const fifteenMinuteIntervalMs = 1000 * 60 * 15;
                                const minimumTimeValue = acePlotPoints[0].x;
                                const maximumTimeValue = acePlotPoints[acePlotPoints.length - 1].x;
                                let nextIndex = minimumTimeValue;
                                while (nextIndex < maximumTimeValue) {
                                    if (new Date(nextIndex).getMinutes() % 15 === 0) {
                                        positions.push(nextIndex);
                                        nextIndex += fifteenMinuteIntervalMs;
                                    } else {
                                        nextIndex++;
                                    }
                                }

                                return positions;
                            }
                        },
                        // axis to add date to left
                        {
                            title: {
                                text: `${mktDayFriendly}`,
                                align: "low",
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                                offset: -30,
                            },
                            lineWidth: 0,
                        },
                        // axis to add date to right
                        {
                            title: {
                                text: `${nxtDayFriendly}`,
                                align: "high",
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                                offset: -30,
                            },
                            lineWidth: 0,
                        }
                    ],
                    yAxis: [
                        {
                            title:{
                                text: null
                            },
                            tickInterval: 200,
                        },
                    ],
                    series: [
                        {
                            name: "Current ACE Value",
                            type: "line",
                            data: acePlotPoints,
                            color: MisoColors.misoBlue,

                        },
                    ]
                })
            }).catch(() => {});
            chart.hideLoading();
        }

        fetchData();
        const THIRTY_SEC_MS = 1000 * 30;
        const interval = setInterval(() => fetchData(), THIRTY_SEC_MS)

        // return fires on unmount, prevent memory leak
        return() => clearInterval(interval);
    }, []);

    return (
        <div className="chart">
        <VCenteredModal show={showModal} onHide={() => setShowModal(false)} headercontent={modalHeader} bodycontent={modalBody} />
            <div className="interval">{intervalString}</div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
            />
        </div>
    )
}
