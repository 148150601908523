
import { AxisLabelsFormatterContextObject } from "highcharts";

export const formatNumber = (num: Number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); // comma separated value
}

export const x48To24hr = (ctx: AxisLabelsFormatterContextObject): string => {
    return ((ctx.value as number) % 24).toString();
}

export const formatForAxis = (dt: Date): string => {
    return dt.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
}

export const formatShortDateForAxis = (dt: Date): string => {
    return dt.toLocaleDateString('en-us', {
        month: 'short',
        day: 'numeric'
    });
}