import { useEffect, useState, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'

import { MisoColors } from '../colors';
import { formatNumber } from '../Utils/StringFormatHelpers';
import { CSAT_PAPI_SUFFIX, DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, POPOUT_MESSAGE } from '../Constants';
import { X_TICK_INTERVAL } from '../Utils/DateHelpers'
import { ChartProps } from '../Interfaces/Charts';
import VCenteredModal from '../Components/VCenteredModal';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Button } from 'react-bootstrap';
import { Download, DownloadFromJson } from '../Utils/Downloader';
import CsvDownloadButton from 'react-json-to-csv';
import exportFromJSON from 'export-from-json';

type CsatDataPoint = {
    id: Number,
    timeEst: string,
    demand?: Number,
    committedCapacity?: Number,
    demandForecast?: Number,
    committedCapacityForecast?: Number,
    availableCapacity?: Number
}

let demandSeries: any[] = [] ;
let committedCapSeries: any[] = [];
let demandForecastSeries: any[] = [];
let committedCapForecastSeries: any[] = [];
let availableCapSeries: any[] = [];
let timeCutover: number = Date.now();
const millisIn4Hrs = 14400000;
const millisIn5Hrs = 18000000;
const darkGreen = "#487629";

let intervalString: string;

const serviceUrl: string = `${process.env.REACT_APP_PUBLIC_API_URL}${CSAT_PAPI_SUFFIX}`;
let data: any;

async function getData() {
    const response = await fetch(serviceUrl);
    data = await response.json();
    return format(data as CsatDataPoint[]);
}

function getDstOffset() {
    const stdTimezoneOffset = () => {
        var jan = new Date(0, 1)
        var jul = new Date(6, 1)
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
    }

    var today = new Date()
    
    const isDstObserved = (today: Date) => {
        return today.getTimezoneOffset() < stdTimezoneOffset()
    }

    if (isDstObserved(today)) {
        return millisIn4Hrs; // EDT
    } else {
        return millisIn5Hrs; // EST
    }
}

function formatTime(dt: Date) : number{
    return new Date(dt).getTime() - getDstOffset();
}

function format(data: CsatDataPoint[]) {
    demandSeries = [] ;
    committedCapSeries = [];
    demandForecastSeries = [];
    committedCapForecastSeries = [];
    availableCapSeries = [];

    var prevPoint: CsatDataPoint;
    var prevTime: number;
    var time: number;

    data.forEach((point, i) => {
        if (isPointValid(prevPoint, point)) {
            time = formatTime(new Date(`${point.timeEst}-0400`));
            demandSeries.push({x: time, y: point.demand});
            committedCapSeries.push({x: time, y: point.committedCapacity});
            demandForecastSeries.push({x: time, y: point.demandForecast});
            committedCapForecastSeries.push({x: time, y: point.committedCapacityForecast});
            availableCapSeries.push({x: time, y: point.availableCapacity});

            if(point.demandForecast != null && prevPoint.demandForecast == null){
                timeCutover = prevTime;
                var dt= new Date(timeCutover);
                intervalString = `${dt.getDate().toString().padStart(2, "0")}-${dt.toLocaleString('default', {month: 'long'})}-${dt.getFullYear()} - Interval ${((dt.getHours() + 4) % 12).toString().padStart(2, "0")}:${dt.toLocaleTimeString('en-US', {minute: "2-digit"})} EST`;
                demandForecastSeries[i-1] = {x: prevTime, y: prevPoint.demand, options: { custom: { tooltip: false }}};
                committedCapForecastSeries[i-1] = {x: prevTime, y: prevPoint.committedCapacity, options: { custom: { tooltip: false }}};
                availableCapSeries[i-1] = {x: prevTime, y: prevPoint.committedCapacity, options: { custom: { tooltip: false }}};
            }

            prevPoint = point;
            prevTime = time;
        }
    });
}

function isPointValid(prevPoint: CsatDataPoint, point: CsatDataPoint) : boolean  {
    if(prevPoint == null) return true;
    return (
           (prevPoint.demand == null || point.demand == null || (Math.abs((prevPoint.demand as number) - (point.demand as number)) < 30000))
        && (prevPoint.committedCapacity == null || point.committedCapacity == null || (Math.abs((prevPoint.committedCapacity as number) - (point.committedCapacity as number)) < 30000))
        && (prevPoint.demandForecast == null || point.demandForecast == null || (Math.abs((prevPoint.demandForecast as number) - (point.demandForecast as number)) < 30000))
        && (prevPoint.committedCapacityForecast == null || point.committedCapacityForecast == null || (Math.abs((prevPoint.committedCapacityForecast as number) - (point.committedCapacityForecast as number)) < 30000))
        && (prevPoint.availableCapacity == null || point.availableCapacity == null || (Math.abs((prevPoint.availableCapacity as number) - (point.availableCapacity as number)) < 30000))
    );
}

export default function SupplyAndDemandChart(props: ChartProps) {
    const [options, setOptions] = useState(initialOptions);
    const chartRef = useRef<HighchartsReactRefObject>(null);
    const [showModal, setShowModal] = useState(false);
    const modalHeader: React.ReactNode = <span>Supply and Demand</span>;
    const [modalBody, setModalBody] = useState<React.ReactNode>(<></>);
    NoDataToDisplay(Highcharts);
    
    useEffect(() => {
        let downloadBody: React.ReactNode = <div className="download-modal">
            <Button className="download-btn" onClick={() => {Download(serviceUrl, "SupplyAndDemand", "json")}}>Download JSON</Button>
            <CsvDownloadButton data={data} delimiter={","} className="download-btn btn btn-primary" filename='SupplyAndDemand.csv'>Download CSV</CsvDownloadButton>
            <Button className="download-btn" onClick={() => DownloadFromJson(data, "SupplyAndDemand", exportFromJSON.types.xml)}>Download XML</Button>
        </div>;
        switch (props.buttonClicked) {
            case DESCRIPTION_MESSAGE:
                setModalBody(<><span>{`This chart is a graphical representation of MISO's power supply (capacity) and demand using Real-Time actuals (solid lines) and the forecasted supply (capacity) and demand (dotted lines). Committed capacity includes generating units based on latest commitment plan, as well as forecasted wind and solar generation output and Net Schedule Interchange.  It does not include capacity that may not be delivered due to transmission congestion.  Available Capacity represents additional economic generation resources available to come on-line if needed. "Cleared Demand" is from the Day Ahead market.`}</span></>);
                setShowModal(true);
                break;
            case DOWNLOAD_MESSAGE:
                setModalBody(downloadBody);
                setShowModal(true);
                break;
            case POPOUT_MESSAGE:
                const newWinddow = window.open('/charts/supplydemand', '_blank', 'width=800,height=600,noopener,noreferrer');
                if (newWinddow) newWinddow.opener = null;
                break;
            default:
                break;
        }
    }, [props.buttonClicked]);

    useEffect(() => {
        function fetchData(): void {
            if(chartRef.current === null){
                return;
            }
            const chart = chartRef.current.chart;
            chart.showLoading();

            getData()
            .then(_ => {
                setOptions ({
                    series: [
                        // specific options for the series instance
                        {
                            name: "Committed Capacity",
                            description: "Committed Capacity",
                            type: 'spline',
                            data: committedCapSeries,
                            color: MisoColors.darkGreen, // dark green
                        },
                        {
                            name: "Demand",
                            description: "Demand",
                            type: 'spline',
                            data: demandSeries,
                            color: MisoColors.misoBlue,
                        },
                        {
                            name: "Available Capacity",
                            description: "Available Capacity",
                            type: 'spline',
                            data: [],
                            dashStyle: 'ShortDash',
                            color: MisoColors.mediumGreen, // medium green
                            id: "Available Capacity",
                        },
                        {
                            name: "Available Capacity",
                            description: "Available Capacity",
                            type: 'areaspline',
                            data: availableCapSeries,
                            dashStyle: 'ShortDash',
                            color: MisoColors.mediumGreen, // medium green
                            showInLegend: false,
                            fillColor: {    
                                linearGradient: { 
                                    x1: 0, x2: 0, y1: 0, y2: 1 
                                },    
                                stops: [        
                                    [0, MisoColors.veryLightGreen],  // very light green  
                                    [1, 'white']    
                                ]
                            },
                            linkedTo: "Available Capacity",
                        },
                        {
                            name: "Committed Capacity Forecast",
                            description: "Committed Capacity Forecast",
                            type: 'areaspline',
                            data: committedCapForecastSeries,
                            dashStyle: 'Dot',
                            color: darkGreen, // dark green
                            showInLegend: false,
                            fillColor: {    
                                linearGradient: { 
                                    x1: 0, x2: 0, y1: 0, y2: 1 
                                },    
                                stops: [        
                                    [0, MisoColors.veryLightGreen],  // very light green  
                                    [1, 'white']    
                                ]
                            },
                            linkedTo: "Committed Capacity Forecast",
                        },
                        {
                            name: "Committed Capacity Forecast",
                            description: "Committed Capacity Forecast",
                            type: 'spline',
                            data: [],
                            dashStyle: 'Dot',
                            color: darkGreen, // dark green
                            id: "Committed Capacity Forecast",
                        },
                        {
                            name: "Demand Forecast",
                            description: "Demand Forecast",
                            type: 'areaspline',
                            data: demandForecastSeries,
                            dashStyle: 'ShortDash',
                            color: MisoColors.misoBlue,
                            showInLegend: false,
                            fillColor: {    
                                linearGradient: { 
                                    x1: 0, x2: 0, y1: 0, y2: 1 
                                },    
                                stops: [        
                                    [0, MisoColors.lightBlue],        
                                    [1, 'white']    
                                ]
                            },
                            linkedTo: "Demand Forecast",
                        },
                        {
                            name: "Demand Forecast",
                            description: "Demand Forecast",
                            type: 'spline',
                            data: [],
                            dashStyle: 'ShortDash',
                            color: MisoColors.misoBlue,
                            id: "Demand Forecast",
                        },
                    ],
                    xAxis: {
                        plotLines: [{
                            color: '#C0C0C0', // Grey
                            width: 4,
                            value: timeCutover,
                            zIndex: 3
                        }]
                    },
                })
            }).catch(() => {});
            chart.hideLoading();
        }

        fetchData();
        const FIFTEEN_MIN_MS = 1000 * 60 * 15;
        const interval = setInterval(() => fetchData(), FIFTEEN_MIN_MS)

        // return fires on unmount, prevent memory leak
        return() => clearInterval(interval);

    }, []);

    return (
        <div className='chart'>
            <VCenteredModal show={showModal} onHide={() => setShowModal(false)} headercontent={modalHeader} bodycontent={modalBody} />
            <div className="interval">{intervalString}</div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
            />
        </div>
    )
}


let initialOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    title: {
        text:'',
    },
    lang: {
        noData: "No data was received"
    },
    chart: {
        height:400,
        reflow: true,
        style: {
            fontFamily: 'Lato, Arial, Helvetica, sans-serif',
            whiteSpace: "wrap"
        },
    },
    plotOptions: {
        spline: {
            // shared options for all spline series
            marker: {
                enabled: false
            },
            lineWidth: 3
        },
        areaspline: {
            // shared options for all line series
            marker: {
                enabled: false
            },
            lineWidth: 3,
            opacity: .75
        }
    },
    series: [],
    legend: {
        enabled: true,
        width: '100%',
        layout: 'horizontal',
        itemMarginBottom: 5,
        itemStyle: {
            display: "flex"
        },
        className:'w-100 d-flex justify-content-between',
        labelFormatter: function () {
            return '<div class="fs14 w-100 d-flex justify-content-between margin-btm-5"><div class="bold">' + this.name + " </div> <div class='fw-normal'> </div></div>";
        }
    },
    yAxis: {
        title:{
            text: "MWs",
            style: {
                fontWeight: "bold",
            },
        },
    },
    xAxis: {
        title: {
            text: `${new Date().toLocaleDateString('en-us', { month:"long", day:"numeric" })} (Hours EST)`,
            style: {
                fontWeight: 'bold',
                color: MisoColors.black,
            },
        },
        type: 'datetime',
        dateTimeLabelFormats : {
            hour: '%k',
        },
        tickInterval: X_TICK_INTERVAL,
        labels: {
            format: '{value:%k}'
        },
        plotLines: [{
            color: '#C0C0C0', // Grey
            width: 4,
            value: timeCutover,
            zIndex: 3
        }]
    },
    tooltip: {
        shared: true,
        formatter: function(){
            if(this.points == null) return false;
            return this.points.reduce(formatTooltip, '<b>' + formatTooltipTime(this.x as number) + '</b>'); // points.reduce(format func(prev_val, val), initial_prev_val)
        }
    },
};


function formatTooltipTime(dtNum: number){
    var dt = new Date(dtNum);
    var time = `${dt.getUTCHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')} EST`;
    return time;
}

// s = previous value (i.e. contents of current tool tip) OR initial value
// point = point obj for current series's entry (y value) at this x
function formatTooltip(s: string, point: Highcharts.TooltipFormatterContextObject): string{

    // if this point is to be excluded, return current tooltip
    if(point.point.options.custom && !point.point.options.custom["tooltip"] ) return s;

    // keep the existing tooltip, add break line
    var tooltip = s + '<br />';
    
    // now add to it
    tooltip += '<span style="color:'+ point.color +'">\u25cf</span> '+ point.series.name +': <b>' + formatNumber(point.y as number) +' MW</b>';
    return tooltip;
}