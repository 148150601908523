import { useEffect, useRef, useState } from 'react';
import { CSAT_NET_UNCERTAINTY_SUFFIX, DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, POPOUT_MESSAGE } from '../Constants';
import '../styles/NetUncertainty.scss';
import moment from 'moment';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { ChartProps } from '../Interfaces/Charts';
import { Download } from '../Utils/Downloader';
import { Button } from 'react-bootstrap';
import VCenteredModal from '../Components/VCenteredModal';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

type CsatNetUncertaintyRecord = {
    forecastPeakHourEst: Date,
    region: string,
    forecastRisk: string,
    committmentThresholdMw: Number,
    forecastCreatedTime: Date,
}

let csatNetUncertaintyRecords: CsatNetUncertaintyRecord[] = [];

async function parseData(data: any) {
    csatNetUncertaintyRecords = data as CsatNetUncertaintyRecord[];
}

let initialOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    chart: {
        type: 'bar',
        height: 140,
        style: {
            fontFamily: 'Lato, Arial, Helvetica, sans-serif',
            whiteSpace: "wrap"
        },
    },
    title: {
        text: "", // Remove the chart title
        align: "left",
    },
    lang: {
        noData: "No data was received"
    },
    xAxis: {
        visible: false,
        min: 0,
        max: 2,
    },
    yAxis: {
        visible: false,
        min: 0,
        max: 30,
        title: {
            text: undefined
        },
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        bar: {
            stacking: 'normal',
            pointWidth: 30,
            states: {
                hover: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return this.series.name;
                },
                align: 'center',
                verticalAlign: 'left',
                y: 40,
                style: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    color: '#000'
                }
            }
        },
        scatter: {
            states: {
                hover: {
                    enabled: false
                }
            }
        }
    },
    tooltip: {
        enabled: false
    },
};

export default function NetUncertainty(props: ChartProps) {
    const systemWideGaugeRef = useRef<HighchartsReactRefObject>(null);
    const [systemWideGaugeOptions, setSystemWideGaugeOptions] = useState<Highcharts.Options>(initialOptions);
    const northCentralGuageRef = useRef<HighchartsReactRefObject>(null);
    const [northCentralGaugeOptions, setNorthCentralGaugeOptions] = useState<Highcharts.Options>(initialOptions);
    const southGaugeRef = useRef<HighchartsReactRefObject>(null);
    const [southGaugeOptions, setSouthGaugeOptions] = useState<Highcharts.Options>(initialOptions);
    const [buttonClicked] = [props.buttonClicked];
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState<React.ReactNode>(<></>);
    const modalHeader: React.ReactNode = <span>Net Uncertainty for Short-Term Reserve Requirement</span>;

    NoDataToDisplay(Highcharts);

    function getReadableDate(): string {
        if (csatNetUncertaintyRecords.length > 0) {
            return  "Operating Day - " + moment(csatNetUncertaintyRecords[0].forecastPeakHourEst).format('DD-MMM-yyyy');
        }
        return '';
    }

    function getMarkerPositionForRisk(risk: string) {
        switch (risk) {
            case '1-Low (Green)':
                return 5;
            case '2-Medium (Yellow)':
                return 15;
            case '3-High (Red)':
                return 25;
            default:
                return 0;
        }
    }

    useEffect(() => {
        const descriptionBody: React.ReactNode = <><span>The Net Uncertainty* model dynamically sets MISO’s Short-Term Reserve (STR) Requirement. The model predicts low, medium or high uncertainty for the next operating day. A low or medium prediction sets normal STR requirements. A high uncertainty prediction sets high STR requirements. Should MISO operations enter a Capacity Advisory or higher, per EOP-002, the STR requirement automatically moves to “high” in order to preposition the grid for the elevated risk. This table is updated daily before the Day-Ahead market closes for the target market day.<br /><br />*Net Uncertainty is comprised of load, wind, solar, thermal generation availability, and net scheduled interchange.</span></>;
        let downloadBody: React.ReactNode = <div className="download-modal">
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_PUBLIC_API_URL}${CSAT_NET_UNCERTAINTY_SUFFIX}`, "NetUncertainty", "json")}>Download JSON</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_PUBLIC_API_URL}${CSAT_NET_UNCERTAINTY_SUFFIX}`.replace("json", "csv"), "NetUncertainty", "csv")}>Download CSV</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_PUBLIC_API_URL}${CSAT_NET_UNCERTAINTY_SUFFIX}`.replace("json", "xml"), "NetUncertainty", "xml")}>Download XML</Button>
        </div>;

        switch (buttonClicked) {
            case DESCRIPTION_MESSAGE:
                setModalBody(descriptionBody);
                setShowModal(true);
                break;
            case DOWNLOAD_MESSAGE:
                setModalBody(downloadBody);
                setShowModal(true);
                break;
            case POPOUT_MESSAGE:
                const newWinddow = window.open('/charts/netunc', '_blank', 'width=800,height=600,noopener,noreferrer');
                if (newWinddow) newWinddow.opener = null;
                break;
            default:
                break;
        }
    }, [buttonClicked]);

    useEffect(() => {
        function getChartOptionsForRecord(region: string): Highcharts.Options {
            let recordForRegion = csatNetUncertaintyRecords.filter(r => r.region === region)[0];
            if (!recordForRegion) return initialOptions;
            let riskY = getMarkerPositionForRisk(recordForRegion.forecastRisk);
    
            return {
                ...initialOptions,
                title: {
                    text: `${recordForRegion.region}`,
                },
                series: [
                    {
                        name: '3 - High',
                        data: [10],
                        color: '#E7503D',
                    } as Highcharts.SeriesBarOptions, // Use SeriesBarOptions for bar series
                    {
                        name: '2 - Medium',
                        data: [10],
                        color: '#FFC844',
                    } as Highcharts.SeriesBarOptions, // Use SeriesBarOptions for bar series
                    {
                        name: '1 - Low',
                        data: [10],
                        color: '#80BC42',
                    } as Highcharts.SeriesBarOptions, // Use SeriesBarOptions for bar series
                    {
                        type: 'scatter',
                        showInLegend: false,
                        data: [
                            {
                                x: 0.5, // Adjust this value to position vertically
                                y: riskY // Adjust this value to position laterally
                            },
                        ],
                        marker: {
                            symbol: 'triangle',
                            radius: 10,
                            fillColor: '#000',
                            lineColor: '#FFF',
                            lineWidth: 2,
                        }
                    }                        
                ]
            };
        }
        
        function fetchData(): void {
            if(systemWideGaugeRef.current === null ||
                northCentralGuageRef.current === null ||
                southGaugeRef.current === null
            ) {
                return;
            }

            const systemWideChart = systemWideGaugeRef.current.chart;
            const northCentralChart = northCentralGuageRef.current.chart;
            const southChart = southGaugeRef.current.chart;
            systemWideChart.showLoading();
            northCentralChart.showLoading();
            southChart.showLoading();

            fetch(process.env.REACT_APP_PUBLIC_API_URL + CSAT_NET_UNCERTAINTY_SUFFIX)
                .then(response => {
                    return response.json();
                }).then(data => {
                    parseData(data);
                    setSystemWideGaugeOptions(getChartOptionsForRecord('Systemwide'));
                    setNorthCentralGaugeOptions(getChartOptionsForRecord('North/Central'));
                    setSouthGaugeOptions(getChartOptionsForRecord('South'));
                }).catch(() => {});
            
            systemWideChart.hideLoading();
            northCentralChart.hideLoading();
            southChart.hideLoading();
        }

        fetchData();
    }, []);

  
    return (
        <div className='net-uncertainty'>
            <VCenteredModal show={showModal} onHide={() => setShowModal(false)} headercontent={modalHeader} bodycontent={modalBody} />
            <div className='table-header'>
                <span className="date">{getReadableDate()}</span>
            </div>
            <div className="net-uncertainty-chart">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={systemWideGaugeOptions}
                    ref={systemWideGaugeRef}
                />
                <HighchartsReact
                    highcharts={Highcharts}
                    options={northCentralGaugeOptions}
                    ref={northCentralGuageRef}
                />
                <HighchartsReact
                    highcharts={Highcharts}
                    options={southGaugeOptions}
                    ref={southGaugeRef}
                />
            </div>
            <div className="footer-link">
                <a href="https://www.misoenergy.org/markets-and-operations/real-time--market-data/market-reports/#nt=/MarketReportType:Summary/MarketReportName:Multiday%20Operating%20Margin%20Forecast%20Report%20(xlsx)">
                    Short-term reserve requirements
                </a>
            </div>
        </div>
    );
}