import Disclaimer from "../Components/Disclaimer";
import routes from "../routes";

export default function Home() {

    return(
        <div className="home"> 
            <div className="banner">
                <div className="banner-title">
                    MISO Public Charts {(process.env.NODE_ENV !== "production") ? ` - ${process.env.NODE_ENV}` : ""} - {(process.env.REACT_APP_VERSION) ? `v${process.env.REACT_APP_VERSION}` : ""}
                </div>
            </div>

            <h2>Current Charts:</h2>
            {routes.map((r) => {
                    if(r.name !== "Home") return (
                        <> 
                            <a href={`/charts${r.route}`}>{r.name}</a>
                            {r.options?.map((o) => 
                                <>
                                    &nbsp; | &nbsp;
                                    <a href={`/charts${r.route}?${o.option}`}>{o.description}</a>
                                </>
                            )}
                            {r.addLineBreak && <br />}
                            <br />
                        </>
                    )
                    else return <></>
                }
                )
            }
            <div className="bottom">
                <Disclaimer extraMargin={false} />
            </div>
        </div>
    )
}