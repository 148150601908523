
export const formatTooltipDateEST = (day: Date): string => {
    return day.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
    });
}

export const formatTooltipDateOnly = (day: Date): string => {
    return day.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
}


export const formatTooltipDateWithMinutes = (day: Date): string => {
    return day.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
}

export const formatTooltipTimeToHoursSinceMidnight = (x: number, mktDay: Date): string => {
    var dt = new Date(mktDay);
    dt.setHours(dt.getHours() + x)
    return `${formatTooltipDateEST(dt)} EST`;
}