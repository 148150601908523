// import AreaControlError from "../Charts/AreaControlError";
import CombinedWindSolar from "../Charts/CombinedWindSolar";
import SupplyAndDemandChart from "../Charts/SupplyAndDemand";
import FuelMix from "../Charts/FuelMix";
import GenerationOutages from "../Charts/GenerationOutages";
import Interchange from "../Charts/Interchange";
import RealTimeTotalLoad from "../Charts/RealTimeTotalLoad";
import RegionalDirectionalTransfer from "../Charts/RegionalDirectionalTransfer";
import NetUncertainty from "../Charts/NetUncertainty";
import Disclaimer from "../Components/Disclaimer";
import Pod from "../Components/Pod";

export default function OperationsDisplays() {
    return (
        <>
            <div className="podContainer">
                <div className="chart-row">
                    <div className="column-2">
                        <Pod title="Fuel Mix" 
                            Chart={FuelMix}
                            showPopout={true}
                            hideDisclaimer={true} />
                    </div>
                    <div className="column-2">
                        <Pod title="Supply and Demand" 
                             Chart={SupplyAndDemandChart} 
                             showPopout={true}
                             hideDisclaimer={true} />
                    </div>
                </div>
                <div className="chart-row">
                    <div className="column-2">
                        <Pod title="Real-Time Total Load" 
                            Chart={RealTimeTotalLoad} 
                            showPopout={true}
                            hideDisclaimer={true} />
                    </div>
                     <div className="column-2">
                        <Pod title="Net Uncertainty for Short-Term Reserve Requirement" 
                            Chart={NetUncertainty} 
                            showPopout={true} 
                            hideDisclaimer={true} />
                    </div>
                </div>
                <div className="chart-row">
                    <div className="column-2">
                        <Pod title="Wind and Solar Forecast and Real-Time Generation"
                        Chart={CombinedWindSolar} 
                             showPopout={true}
                             hideDisclaimer={true}
                             chartSpecificProps={{"twoDays": "true"}} />
                    </div>
                    <div className="column-2">
                        <Pod title="Region-Wide Total Imports(-)/Exports(+)"
                        Chart={Interchange} 
                             showPopout={true}
                             hideDisclaimer={true} />
                    </div>
                </div>
                <div className="chart-row">
                    <div className="column-2">
                        <Pod title="Regional Directional Transfer"
                        Chart={RegionalDirectionalTransfer} 
                             showPopout={true}
                             hideDisclaimer={true} />
                    </div>
                    <div className="column-2">
                        <Pod title="Generation Outages"
                        Chart={GenerationOutages} 
                             showPopout={true}
                             hideDisclaimer={true} />
                    </div>
                </div>
            </div>
            <Disclaimer  extraMargin={true}/>
        </>
    )
}
