import Highcharts from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'
import { useEffect, useRef, useState } from 'react'

import { DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, GEN_OUT_SUFFIX, POPOUT_MESSAGE } from '../Constants'
import { ChartProps, XYPoint } from '../Interfaces/Charts'
import { GenerationOutagesData, GenerationOutagesInterval } from '../Interfaces/DataBroker'
import { FIFTEEN_MIN_MS, convertDateTimeToISO } from '../Utils/DateHelpers'
import { formatNumber } from '../Utils/StringFormatHelpers'
import { MisoColors } from '../colors'
import { formatTooltipDateWithMinutes } from '../Utils/ToolTipHelpers'
import VCenteredModal from '../Components/VCenteredModal'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Button } from 'react-bootstrap'
import { Download } from '../Utils/Downloader'

// setup series
let unplanned: XYPoint[] = [];
let planned: XYPoint[] = [];
let forced: XYPoint[] = [];
let derated: XYPoint[] = [];

// setup dates and labels
let intervalString: string;

let initialOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    title:{
        text: ''
    },
    lang: {
        noData: "No data was received"
    },
    plotOptions: {
        line: {
            marker: {
                enabled: true,
                symbol: 'circle',
                fillColor: 'white',
                lineColor: undefined,
                lineWidth: 2,
                states:{
                    hover:{
                        enabled: true,
                        lineColor: undefined,
                    },
                },
            },
        },
    },
    xAxis: {
        crosshair: {
            color: MisoColors.black,
        }
    },
    yAxis: {
        title: {
            text: "MWs",
            style: {
                fontWeight: "bold",
            },
        },
        tickInterval: 5000,
    },
    tooltip: {
        shared: true,
        formatter: function() {
            if(this.points == null) return false;
            const x = new Date(this.x as string);
            return this.points.reduce(formatTooltip, '<b>' + formatTooltipDateWithMinutes(x) + '</b>'); // points.reduce(format_func(prev_val, val), initial_prev_val)
        }
    },
    series: []
};

function formatTooltip(s: string, point: Highcharts.TooltipFormatterContextObject): string{
    
    const y = point.y as number;

    // keep the existing tooltip, add break line
    var tooltip = s + '<br />';
    
    // now add to it
    tooltip += '<span style="color:'+ point.color +'">\u25cf</span> '+ point.series.name +': <b>' + formatNumber(y) +' MW</b>';
    return tooltip;
}

function parseData(data: GenerationOutagesData): void {
    // clear out the data
    unplanned = [];
    planned = [];
    forced = [];
    derated = [];

    intervalString = data.RefId;

    // parse the data
    data.Days.forEach((interval: GenerationOutagesInterval) => {
        var otgDt = convertDateTimeToISO(interval.OutageDate);
        otgDt.setUTCHours(0, 0, 0, 0);
        const x = otgDt.getTime();
        unplanned.push({x, y: parseFloat(interval.Unplanned)});
        planned.push({x, y: parseFloat(interval.Planned)});
        forced.push({x, y: parseFloat(interval.Forced)});
        derated.push({x, y: parseFloat(interval.Derated)});
    });
}

export default function GenerationOutages(props: ChartProps) {

    const [options, setOptions] = useState(initialOptions);
    const chartRef = useRef<HighchartsReactRefObject>(null);
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState<React.ReactNode>(<></>);
    const modalHeader: React.ReactNode = <span>Generation Outages</span>;
    NoDataToDisplay(Highcharts);

    useEffect(() => {
        const descriptionBody: React.ReactNode = <><span>
            The Generation Outages, Plus and Minus Five Days, graph provides a daily snapshot of available outage detail. Graph refreshes daily and is a point-in-time view intended to provide awareness and should not be used for market settlement or report reconciliation. Please reference BPM-008 for expanded definition on outage type.
            </span><br /><br /> <span>
            • Unplanned – Includes non derate outages that were submitted as urgent or emergency
            <br />
            • Planned - Equipment is known to be operable with little risk of leading to a forced outage.
            <br />
            • Forced – Outage requests submitted for equipment that unexpectedly goes out of service
            <br />
            • Derated – Generator’s available output is reduced below the machines capability
            <br />
            • Total Outage Megawatts: A summation of 4 categories for the current day.
            </span><br /><br /> <span>
            The graph is updated twice daily at 12 AM and 12 PM</span>
        </>;
        let downloadBody: React.ReactNode = <div className="download-modal">
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${GEN_OUT_SUFFIX}`, "GenerationOutages", "json")}>Download JSON</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${GEN_OUT_SUFFIX}`.replace("json", "csv"), "GenerationOutages", "csv")}>Download CSV</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${GEN_OUT_SUFFIX}`.replace("json", "xml"), "GenerationOutages", "xml")}>Download XML</Button>
        </div>;
        switch (props.buttonClicked) {
            case DESCRIPTION_MESSAGE:
                setModalBody(descriptionBody);
                setShowModal(true);
                break;
            case DOWNLOAD_MESSAGE:
                setModalBody(downloadBody);
                setShowModal(true);
                break;
            case POPOUT_MESSAGE:
                const newWinddow = window.open('/charts/genout', '_blank', 'width=800,height=600,noopener,noreferrer');
                if (newWinddow) newWinddow.opener = null;
                break;
            default:
                break;
        }
    }, [props.buttonClicked]);

    useEffect(() => {
        function fetchData(): void {
            if(chartRef.current === null){
                return;
            }
            const chart = chartRef.current.chart;
            chart.showLoading();

            fetch(process.env.REACT_APP_DATABROKER_URL + "" + GEN_OUT_SUFFIX)
            .then(response => {
                return response.json();
            })
            .then(data => {
                parseData(data);
                setOptions({
                    xAxis: [
                        // Main axis
                        {
                            labels: {
                                formatter: function(this: Highcharts.AxisLabelsFormatterContextObject) {
                                    var dt = new Date(this.value);
                                    return dt.toLocaleDateString('en-us', {
                                        month: 'long',
                                        day: 'numeric',
                                    });
                                },
                            },
                            tickInterval: 24 * 3600 * 1000,
                        },
                    ],
                    series: [
                        {
                            name: "Unplanned",
                            type: "line",
                            data: unplanned,
                            color: MisoColors.royalBlue,
                            marker: {
                                states:{
                                    hover:{
                                        fillColor: MisoColors.royalBlue,
                                    },
                                },
                            },
                        },
                        {
                            name: "Planned",
                            type: "line",
                            data: planned,
                            color: MisoColors.black,
                            marker: {
                                states:{
                                    hover:{
                                        fillColor: MisoColors.black,
                                    },
                                },
                            },
                        },
                        {
                            name: "Forced",
                            type: "line",
                            data: forced,
                            color: MisoColors.misoOrange,
                            marker: {
                                states:{
                                    hover:{
                                        fillColor: MisoColors.misoOrange,
                                    },
                                },
                            },
                        },
                        {
                            name: "Derated",
                            type: "line",
                            data: derated,
                            color: MisoColors.misoGreen,
                            marker: {
                                states:{
                                    hover:{
                                        fillColor: MisoColors.misoGreen,
                                    },
                                },
                            },
                        },
                    ]
                })
            }).catch(() => {} );
            chart.hideLoading();
        }

        fetchData();
        const interval = setInterval(() => fetchData(), FIFTEEN_MIN_MS)

        // return fires on unmount, prevent memory leak
        return() => clearInterval(interval);
    }, []);

    return (
        <div className='chart'>
            <VCenteredModal show={showModal} onHide={() => setShowModal(false)} headercontent={modalHeader} bodycontent={modalBody} />
            <div className="interval">{intervalString}</div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
            />
        </div>
    )
}
