type DisclaimerProps = {
    extraMargin: boolean;
};

export default function Disclaimer(props: DisclaimerProps) {

    var { extraMargin } = props;

  return (
    <div className={extraMargin ? 'disclaimer extra-margin' : 'disclaimer'}>
        <div className='disclaimer-content'>
            * Data is sourced from internal systems and 3rd party data providers to MISO and is considered preliminary and provided at best effort.
        </div>
    </div>
  )
}