import './styles/App.scss';
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "./routes";

function App() {

  // function handleResize(){
  //   window.parent.postMessage({
  //       'height': document.body.scrollHeight
  //   }, '*'); // Use a specific domain instead of '*' for better security  
  // }

  // window.addEventListener('resize', handleResize);

  // setTimeout(() => {
  //   handleResize();
  // }, 500);

  const getRoutes = (allRoutes: any) => 
    allRoutes.map((route: any) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if(route.route) {
        return (
          <Route
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <Routes>
      {getRoutes(routes)}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
