import exportFromJSON from "export-from-json";
import { ExportType } from "export-from-json/dist/types/types";
import saveAs from "file-saver";

export function Download(url: string, fileName: string, format: string): void {
		fetch(url)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        saveAs(new Blob([data], { type: `text/${format}` }), `${fileName}.${format}`);
      });
}

export function DownloadFromJson(json: any, filename: string, format: ExportType): void {
    exportFromJSON({data: json, fileName: filename, exportType: format})
}