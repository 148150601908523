import '../styles/Pod.scss';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { ChartProps } from '../Interfaces/Charts';
import { DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, POPOUT_MESSAGE } from '../Constants';
import { useLocation } from 'react-router';
import Disclaimer from './Disclaimer';

type PodProps = {
    title: string;
    showPopout?: boolean;
    Chart: React.FC<ChartProps & any>;
    hideTitleBar?: boolean;
    hideDisclaimer?: boolean;
    chartSpecificProps?: any;
};


export default function Pod(props: PodProps) {
    const location = useLocation();
    const hideTitle = new URLSearchParams(location.search).get('hideTitleBar');
    const hideDisclaimerUrl = new URLSearchParams(location.search).get('hidedisclaimer') === 'true' || new URLSearchParams(location.search).get('hideDisclaimer') === 'true';
    const showPopoutUrl = new URLSearchParams(location.search).get('showpopout') === 'true';

    var { title, showPopout, Chart, hideTitleBar, hideDisclaimer } = props;
    const [buttonClicked, setButtonClicked] = useState("");

    if(hideTitleBar === null || hideTitleBar === undefined){
        hideTitleBar = hideTitle === 'true';
    }

    if(hideDisclaimer === null || hideDisclaimer === undefined){
        hideDisclaimer = hideDisclaimerUrl;
    }
    
    if(showPopout === null || showPopout === undefined){
        showPopout = showPopoutUrl;
    }

    useEffect(() => { 
        if(buttonClicked === '') return;
        setButtonClicked('');
    }, [buttonClicked]);
    

    return (
        <div className='pod' style={{height:"100%"}}>
            <div className='pod-container'>
                <div className="pod-container-titlebar" hidden={hideTitleBar}>
                    <div className="col-xs-9 titlebar-title">
                        <span>{title}</span>
                    </div>
                    <div className='col-xs-3 pull-right titlebar-buttons'>
                        <span title="Toggle Description" className="miso-icon miso-icon-info">
                            <FontAwesomeIcon icon={faInfoCircle} size="xs" onClick={() => setButtonClicked(DESCRIPTION_MESSAGE)}/>
                        </span>
                        <span title="Download Data" className="miso-icon miso-icon-download">
                            <FontAwesomeIcon icon={faDownload} size="xs"  onClick={() => setButtonClicked(DOWNLOAD_MESSAGE)} />
                        </span>
                        {
                            showPopout && <span title="Open the pod in a new tab" className="miso-icon miso-icon-popout">
                                <FontAwesomeIcon icon={faWindowRestore} size="xs"  onClick={() => setButtonClicked(POPOUT_MESSAGE)} />
                            </span>
                        }
                    </div>
                </div>
                <div className='chart-container'>
                    <div className='chart-area'>
                        <Chart buttonClicked={buttonClicked} {...props.chartSpecificProps}/>
                    </div>
                </div>
            </div>
            { !hideDisclaimer && <Disclaimer extraMargin={false}/> }
        </div>
    )
}