import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

export interface VCenteredModalProps extends ModalProps {
    headercontent: React.ReactNode;
    bodycontent: React.ReactNode;
}

export default function VCenteredModal(props: VCenteredModalProps) {
    return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {props.headercontent}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props.bodycontent}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
}